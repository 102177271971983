const apiRoutes = {
  login: "/user/login",
  userList: "/user/list",
  stationList: "/strum/management/station/list",
  stationInput: "/strum/management/station/input",
  stationEdit: "/strum/management/station/edit",
  stationDelete: "/strum/management/station/delete",
  cabinetList: "/strum/management/station/cabinet/list",
  cabinetInput: "/strum/management/station/cabinet/input",
  cabinetEdit: "/strum/management/station/cabinet/edit",
  cabinetDelete: "/strum/management/station/cabinet/delete",
  transactionList: "/strum/transactional/list",
  transactionLatest: "/strum/transactional/latest",

  getUserList: '/user/list',
  addUser:'/user/register',
  updateStatus:'/user/status',
  updatePassword:'/user/changepassword',
  resendVerifEmail:'/user/reqverifybyadmin',

  getBatteryList: '/strum/battery/list',
  addBattery: '/strum/battery/input',
  updateBattery: '/strum/battery/edit',

  getStationList: "/strum/management/station/list",
  motorcycleList: "/strum/motorcycles/list",
};
export default apiRoutes;
