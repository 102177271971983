import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import { QueryClient, QueryClientProvider } from "react-query";
import PrivateRoute from './components/private-route/PrivateRoute';
import './App.scss';
import "react-select/dist/react-select.css";
import "react-datetime/css/react-datetime.css";

const queryClient = new QueryClient();
const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Login'));

class App extends Component {

  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <HashRouter>
            <React.Suspense fallback={loading()}>
              <Switch>
                <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                <PrivateRoute path="/" name="Dashboard" component={DefaultLayout} />
              </Switch>
            </React.Suspense>
        </HashRouter>
      </QueryClientProvider>
    );
  }
}

export default App;
