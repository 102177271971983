import { createAsyncThunk } from '@reduxjs/toolkit'
import apiRoutes from './routes'
import axios from '../utils/axios'

export const userLogin = createAsyncThunk('auth/userLogin', 
    async (credential,thunkAPI) => {
        return axios
            .post(apiRoutes.login, credential)
            .then(response => response.data)
            .catch(({response}) => thunkAPI.rejectWithValue(response.data.message))
    }
)
