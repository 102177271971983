export const PERSIST_KEY_NAME = "spblku_z1"

export const DATE_ISO8601 = "YYYY-MM-DD HH:mm:ss"
export const DATE_ISO8601_NO_TIME = "YYYY-MM-DD"

export const USER_LEVELS_SELECTION = [
    { value: "1", label: "Admin" },
    { value: "4", label: "Super Admin" } 
]

export const WHITE_LISTED_COMPANY_ID = [26, 96, 1]
