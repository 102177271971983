import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../utils/axios";
import apiRoutes from "../../api/routes";

export const getStation = createAsyncThunk(
  "station/getStation",
  async (props, { rejectWithValue }) => {
    try {
      const result = await instance.post(apiRoutes.stationList);
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addStation = createAsyncThunk(
  "station/addStation",
  async (props, { rejectWithValue }) => {
    try {
      const fd = new URLSearchParams();
      Object.keys(props.data).forEach((key) => {
        fd.append(key, !!props.data[key] ? props.data[key] : "");
      });
      const result = await instance.post(apiRoutes.stationInput, fd);
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editStation = createAsyncThunk(
  "station/editStation",
  async (props, { rejectWithValue }) => {
    try {
      const fd = new URLSearchParams();
      delete props.data.created_on;
      delete props.data.modefied_on;
      Object.keys(props.data).forEach((key) => {
        fd.append(
          key,
          props.data[key] != null && props.data[key] !== undefined
            ? props.data[key]
            : ""
        );
      });
      const result = await instance.post(apiRoutes.stationEdit, fd);
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const deleteStation = createAsyncThunk(
  "station/deleteStation",
  async (props, { rejectWithValue }) => {
    try {
      const fd = new URLSearchParams();
      Object.keys(props.data).forEach((key) => {
        fd.append(key, !!props.data[key] ? props.data[key] : "");
      });
      const result = await instance.post(apiRoutes.stationDelete, fd);
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
