import { createSlice } from "@reduxjs/toolkit";
import {
  addStation,
  deleteStation,
  editStation,
  getStation,
} from "./station.thunk";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

const initialState = {
  station: null,
  selected: null,
  loadingStation: false,
  errorStation: null,
  loadingInputStation: false,
  successInputStation: null,
  errorInputStation: null,
  loadingEditStation: false,
  successEditStation: null,
  errorEditStation: null,
  loadingDeleteStation: false,
  successDeleteStation: null,
  errorDeleteStation: null,
};

export const stationSlice = createSlice({
  name: "station",
  initialState,
  reducers: {
    onSelectedStation: (state, { payload }) => {
      state.selected = payload;
    },
    resetAddInput: (state) => {
      state.loadingInputStation = false;
      state.successInputStation = null;
      state.errorInputStation = null;
    },
    resetEditInput: (state) => {
      state.loadingEditStation = false;
      state.successEditStation = null;
      state.errorEditStation = null;
    },
    resetDeleteInput: (state) => {
      state.loadingDeleteStation = false;
      state.successDeleteStation = null;
      state.errorDeleteStation = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getStation.pending, (state) => {
        state.station = null;
        state.loadingStation = true;
      })
      .addCase(getStation.fulfilled, (state, { payload }) => {
        state.station = payload.data.result;
        state.loadingStation = false;
      })
      .addCase(getStation.rejected, (state, { payload }) => {
        state.errorStation = true;
        state.loadingStation = false;
        if (payload instanceof AxiosError) {
          toast.error(payload.response.data.message);
        }
      })
      .addCase(addStation.pending, (state) => {
        state.loadingInputStation = false;
        state.successInputStation = null;
        state.errorInputStation = null;
      })
      .addCase(addStation.fulfilled, (state) => {
        state.successInputStation = true;
        state.loadingInputStation = false;
      })
      .addCase(addStation.rejected, (state, { payload }) => {
        state.errorInputStation = true;
        state.loadingInputStation = false;
        if (payload instanceof AxiosError) {
          toast.error(payload.response.data.message);
        }
      })
      .addCase(editStation.pending, (state) => {
        state.loadingEditStation = false;
        state.successEditStation = null;
        state.errorEditStation = null;
      })
      .addCase(editStation.fulfilled, (state) => {
        state.successEditStation = true;
        state.loadingEditStation = false;
      })
      .addCase(editStation.rejected, (state, { payload }) => {
        state.errorEditStation = true;
        state.loadingEditStation = false;
        if (payload instanceof AxiosError) {
          toast.error(payload.response.data.message);
        }
      })
      .addCase(deleteStation.pending, (state) => {
        state.loadingDeleteStation = false;
        state.successDeleteStation = null;
        state.errorDeleteStation = null;
      })
      .addCase(deleteStation.fulfilled, (state) => {
        state.successDeleteStation = true;
        state.loadingDeleteStation = false;
      })
      .addCase(deleteStation.rejected, (state, { payload }) => {
        state.errorDeleteStation = true;
        state.loadingDeleteStation = false;
        if (payload instanceof AxiosError) {
          toast.error(payload.response.data.message);
        }
      });
  },
});

export const {
  onSelectedStation,
  resetAddInput,
  resetEditInput,
  resetDeleteInput,
} = stationSlice.actions;

export default stationSlice.reducer;
